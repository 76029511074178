import { Asset, Entry } from "contentful"
import { TypeAdSkeleton } from "../../lib/contentful/ContentfulVzdelanibudoucnostiTypes"
import React from "react"
import styled from "@emotion/styled"
import Image from "next/legacy/image"
import { theme } from "../../styles/styles"
import { ColorPalette } from "../../lib/extractColorsFromImage/extractColorsFromImage"
import { css } from "@emotion/react"
import { invert, readableColor } from "polished"
import { Button, Space, Typography } from "antd"
import Link from "next/link"
import { md } from "../../styles/mediaQuery"

export const Ad = ({ fields: { title, description, imageHorizontal, link, buttonText, buttonColor }, colors, withGradient = false }: Props) => {
  const asset = imageHorizontal as Asset
  const readableTextColor = "#fff"

  return (
    <Link href={link} target="_blank" title={buttonText} legacyBehavior>
      <AdWrapper>
        <Inner>
          <Space direction="vertical" size={4}>
            <AdTitle level={2} style={{ color: readableTextColor, lineHeight: 1.1 }}>
              {title}
            </AdTitle>
            <AdDesc style={{ color: readableTextColor, marginBottom: 0, fontSize: 12, lineHeight: 1.3 }}>{description}</AdDesc>
          </Space>

          <Button
            css={
              buttonColor &&
              css`
                border-color: ${buttonColor};
                background: ${buttonColor};

                &:hover {
                  color: ${readableColor(buttonColor)};
                  border-color: ${buttonColor};
                }
              `
            }>
            {buttonText ?? "Další informace"}
          </Button>
        </Inner>

        <BgImage
          css={
            withGradient &&
            css`
              background: linear-gradient(270deg, ${colors.vibrant.hex}, ${invert(colors.vibrant.hex)});
              background-size: 400% 400%;
              animation: AnimationName 6s ease infinite;
            `
          }>
          <Image
            src={`https:${asset.fields.file.url.toString()}`}
            alt={asset.fields.file.fileName.toString() || `asset-${title}`}
            layout="fill"
            objectFit="cover"
            style={
              withGradient && {
                opacity: 0.6
              }
            }
          />
        </BgImage>
      </AdWrapper>
    </Link>
  )
}

type Props = {
  colors?: ColorPalette
  withGradient?: boolean
} & Entry<TypeAdSkeleton, undefined, string>

const AdWrapper = styled.div`
  label: Ad;
  height: 250px;
  position: relative;
  display: flex;
  transition: ${theme.effects.transition.default};
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;

  img {
    transition: ${theme.effects.transition.default};
  }

  &:hover {
    img {
      opacity: 0.1 !important;
    }
  }
`

const Inner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-btn {
    align-self: flex-end;
    padding-left: 30px;
    padding-right: 30px;
  }

  ${md} {
    padding: 14px;
  }
`

const AdTitle = styled(Typography.Title)``

const AdDesc = styled(Typography.Paragraph)``

const BgImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`
