import React from "react"
import styled from "@emotion/styled"
import { md, sm } from "../../styles/mediaQuery"

const FullwidthBgColor = ({ color, height, children }: Props) => {
  return (
    <FullwidthBgColorWrapper color={color} height={height}>
      {children && children}
    </FullwidthBgColorWrapper>
  )
}

const FullwidthBgColorWrapper = styled.div<Props>`
  ${(props: Props) => `
        z-index: -2;
        position: relative;
        color: white;
        height: calc(80px + ${props.height});
        width: 100%;
        
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: ${props.color};
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
        
        ${md} {
          height: unset;
          min-height: calc(50px + 10vh);
        } 
        
        ${sm} {
          height: unset;
          min-height: calc(50px + 15vh);
        } 
    `}
`

interface Props {
  height: string
  color: string
  children?: React.ReactNode
}

export default FullwidthBgColor
